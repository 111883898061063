export * from "ce/components/RunHistory";
// TODO: this should not be imported from WorkflowIDE page
import { WorkflowRunHistoryPane } from "ee/pages/WorkflowIDE/layouts/components/BottomBar/WorkflowRunHistory";
import { getShowRunHistoryPaneState } from "ee/selectors/workflowRunHistorySelectors";
import React from "react";
import { useSelector } from "react-redux";

export default function RunHistory() {
  // Run history render flag
  const paneVisible = useSelector(getShowRunHistoryPaneState);

  return paneVisible ? <WorkflowRunHistoryPane /> : null;
}
